<template>
  <section id="root">
    <router-view />
  </section>
</template>
<script>
export default {
  created() {
    console.log("sdk-pred");
    console.log('version:3.0.0');

  },

};
</script>
<style lang="scss">
#root {
  width: 100%;
  margin: 0 auto;
}
</style>
